import { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useRaces } from '../../hooks'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import * as appStorage from '../../utils/appStorage'

const useStyles = makeStyles(theme => ({
	racesMenu: {
		padding: 2,
		marginTop: 5,
		width: '100%',
		overflowX: 'scroll',
		'&::-webkit-scrollbar': {
			display: 'none',
		},
		scrollbarWidth: 'none',
	},
	racesButton: {
		//height: '30px'
		//		backgroundColor: theme.palette.secondary.main,
		//	color: 'white',
		borderColor: theme.palette.secondary.main,
		color: theme.palette.secondary.main,
		borderRadius: '1em',
		padding: 5,
	},
}))

function MenuListRaces({
	rankingSelectedRace,
	setRankingSelectedRace,
	setRankingSelectedRT,
	setRankingSelectedOV,
	rankingType,
}) {
	const { races } = useRaces()
	const classes = useStyles()

	const handleChange = value => {
		setRankingSelectedRace(value)
	}

	useEffect(() => {
		if (rankingSelectedRace === null || !races.some(race => race.id === rankingSelectedRace)) {
			setRankingSelectedRace(races[0].id)
		}
		const storageRT = appStorage.get(`selectedRT${rankingSelectedRace}`)
		const storageOV = appStorage.get(`selectedOV${rankingSelectedRace}`)
		if (rankingType === 'RT' && storageRT)
			setRankingSelectedRT(appStorage.get(`selectedRT${rankingSelectedRace}`))
		if (rankingType === 'OV' && storageOV)
			setRankingSelectedOV(appStorage.get(`selectedOV${rankingSelectedRace}`))
	}, [
		races,
		rankingSelectedRace,
		setRankingSelectedRace,
		setRankingSelectedRT,
		setRankingSelectedOV,
		rankingType,
	])

	return (
		<ToggleButtonGroup
			variant="outlined"
			size="small"
			exclusive={true}
			onChange={(event, value) => {
				handleChange(value)
			}}
			value={rankingSelectedRace}
			className={classes.racesMenu}
		>
			{races.map(race => {
				return (
					<ToggleButton key={race.name} value={race.id} className={classes.racesButton}>
						{race.text}
					</ToggleButton>
				)
			})}
		</ToggleButtonGroup>
	)
}

export default MenuListRaces
