import { useState, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'
//import { useIntl } from 'react-intl'
import { isDesktop } from 'react-device-detect'
import { Grid, Typography, IconButton } from '@material-ui/core'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'
import { useConfig, useStateWithLocalStorage } from '../../hooks'
import * as appStorage from '../../utils/appStorage'

const RTS_LIST_QUERY = gql`
	{
		rtsList {
			id
			name
			nameText
			location
			race {
				id
				name
			}
			group {
				id
				name
				message
				isOnline
				isActive
			}
			isScratch
			isCircuit
			isShowOST
			isCones
			isGates
			isCRS
			isNT
			isJokers
			distance
			stagesNames
			stagesCount
		}
	}
`

function MenuListRT({
	rankingSelectedRT,
	setRankingSelectedRT,
	rankingSelectedRace,
	setRankingStages,
	setRankingPropsRT,
	rankingType,
	isData,
	classes,
}) {
	const { config } = useConfig()
	const { mixDisplayRaces: isMix } = config
	const [rtMenuRef, setRtMenuRef] = useState(null)
	const [scrollLeftValue, setScrollLeftValue] = useStateWithLocalStorage('scrollLeftRT', 0)
	const [scrollLeftMax, setScrollLeftMax] = useState(0)
	const [rtsList, setRtsList] = useState([])
	const [nameRT, setNameRT] = useState(null)
	const [messageRT, setMessageRT] = useState(null)

	const {
		loading: rtsListLoading,
		error: rtsListError,
		data: rtsListData,
	} = useQuery(RTS_LIST_QUERY, {
		pollInterval: config.pollInterval || 60000,
	})

	const handleChange = value => {
		const scrollValue = rtMenuRef.scrollLeft
		setScrollLeftValue(scrollValue)
		setRankingSelectedRT(value)
	}

	const handleScroll = orientation => {
		const scrollStep = 200
		let scrollValue =
			orientation === 'left' ? scrollLeftValue - scrollStep : scrollLeftValue + scrollStep
		if (scrollValue < 0) scrollValue = 0
		if (scrollValue > scrollLeftMax) scrollValue = scrollLeftMax
		setScrollLeftValue(scrollValue)
	}

	useEffect(() => {
		if (rtMenuRef) {
			rtMenuRef.scrollLeft = scrollLeftValue
			setScrollLeftMax(rtMenuRef.scrollWidth - rtMenuRef.clientWidth + 10)
		}
	}, [rtMenuRef, scrollLeftValue])

	useEffect(() => {
		if (rtsList.length) {
			const filteredList = rtsList.filter(rt => rt.group.id === rankingSelectedRT)
			const stageNames = filteredList[0]?.stagesNames
			setRankingStages(stageNames)
			const nameRT =
				filteredList.length && filteredList[0].name !== filteredList[0].nameText
					? `${filteredList[0].nameText}: ${filteredList[0].location}`
					: null
			setNameRT(nameRT)
			const message = filteredList.length ? filteredList[0].group.message : null
			setMessageRT(message)
			const propsRT = {
				isCircuit: filteredList[0]?.isCircuit,
				isScratch: filteredList[0]?.isScratch,
				isShowOST: filteredList[0]?.isShowOST,
				isCones: filteredList[0]?.isCones,
				isGates: filteredList[0]?.isGates,
				isCRS: filteredList[0]?.isCRS,
				isNT: filteredList[0]?.isNT,
				isJokers: filteredList[0]?.isJokers,
				distance: filteredList[0]?.distance,
			}
			setRankingPropsRT(propsRT)
		}
	}, [setRankingStages, rankingSelectedRT, rankingType, rtsList, setRankingPropsRT])

	useEffect(() => {
		const rankingSelectedRaceLS = appStorage.get('selectedRace')
		if (
			rtsList.length &&
			rtsList[0].group.isOnline &&
			rankingSelectedRace === rankingSelectedRaceLS &&
			(rankingSelectedRT === null || !rtsList.some(rt => rt.group.id === rankingSelectedRT))
		) {
			setRankingSelectedRT(rtsList[0].group.id)
		}
	}, [rankingSelectedRT, setRankingSelectedRT, rtsList, rankingSelectedRace])

	useEffect(() => {
		if (rtsListData) {
			const data = isMix
				? rtsListData.rtsList
				: rtsListData.rtsList.filter(rt => rt.race.id === rankingSelectedRace)
			setRtsList(data)
		}
	}, [rankingSelectedRace, setRtsList, rtsListData, isMix])

	if (rtsListLoading) return 'Loading...'
	if (rtsListError) return `Error! ${rtsListError && rtsListError.message}`

	//console.log('scrollLeftMax:', scrollLeftMax)
	return (
		<>
			{isDesktop && scrollLeftMax > 10 && (
				<IconButton onClick={() => handleScroll('left')} disabled={scrollLeftValue === 0}>
					<ArrowBackIos />
				</IconButton>
			)}
			<ToggleButtonGroup
				variant="outlined"
				size="small"
				exclusive={true}
				onChange={(event, value) => {
					if (value || value === 0) {
						handleChange(value)
					}
				}}
				value={rankingSelectedRT}
				className={classes.rtMenu}
				ref={ref => setRtMenuRef(ref)}
			>
				{rtsList.map(rt => {
					const groupID = rt.group.id
					const inactiveClass = rt.group.isActive === true ? '' : classes.rtInactive
					//const prefRT = intl.formatMessage({ id: rt.name.substring(0, 2).toLowerCase() })

					return (
						<ToggleButton
							key={groupID}
							value={groupID}
							disabled={!rt.group.isOnline}
							className={`${classes.rtButton} ${inactiveClass}`}
						>
							{/*`${prefRT}${rt.name.substring(2)}`*/}
							{config.rallyType !== 'TAO' ? `${rt.nameText}` : `${rt.nameText}: ${rt.location}`}
						</ToggleButton>
					)
				})}
			</ToggleButtonGroup>
			{isDesktop && scrollLeftMax > 10 && (
				<IconButton
					onClick={() => handleScroll('right')}
					disabled={scrollLeftValue === scrollLeftMax}
				>
					<ArrowForwardIos />
				</IconButton>
			)}
			<Grid container spacing={2} className={classes.rootMessage}>
				<Grid item xs={12} container>
					<Grid item xs={1} className={classes.left} />
					<Grid item xs={10} className={classes.middle}>
						{true && (
							<Typography noWrap className={`${classes.headerText} ${false && classes.blink}`}>
								{config.isDisplayRTName && nameRT}
								{config.rallyType !== 'TAO' && messageRT}
							</Typography>
						)}
					</Grid>
					<Grid item xs={1} className={classes.right} />
				</Grid>
			</Grid>
		</>
	)
}

export default MenuListRT
