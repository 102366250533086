import { gql } from '@apollo/client'

export const CORE_CONFIG_FIELDS = gql`
	fragment CoreConfigFields on Config {
		rallyCode
		rallyName
		rallyType
		days
		legs
		isLegsOV
		isMultiRaces
		isRiders
		ridersName
		isPrologue
		isPrologueDay1
		isCH
		isCHS
		isJokersCHS
		isSC
		isCP
		mixDisplayRaces
		isSplitCHS
		isDecimal
		isTimeTotal
		isDisplayRetirements
		isDisplayAVG
		isGroup
		isDisplayGroup
		isDisplayGroupPos
		isClass
		isDisplayClass
		isDisplayClassPos
		isDisplayCoef
		isDisplayCapacity
		isDisplayCapacityStartingList
		isDisplayCarRT
		isDisplayCarOV
		isDisplayTeam
		isDisplayFlags
		flagsServer
		rtPrefix
		nameCH
		nameSC
		nameDay
		nameCar
		isRallyNameHeader
		primaryColor
		secondaryColor
		pollInterval
		isPartialOV
		timeZone
		isRoadbook
		isDepartureSplit
		roadbookInterval
		isDeparturePM
		isDisplayOV
		isDisplayRT
		isDisplayDepartures
		isDisplayRTName
	}
`
