import { useState, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'
import { FormattedMessage, useIntl } from 'react-intl'
import { Tooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { MenuOV, FooterOV } from '../components/SubMenus'
import { MUITable } from '../components/MUITable'
import { pointsToTime } from '../utils/timeConverter'
//import { Image } from 'cloudinary-react'
import { useConfig, useRaces, useStateWithLocalStorage } from '../hooks'

const useStyles = props =>
	makeStyles(theme => ({
		container: {
			height: '100%',
		},
		menu: {
			height: `${props.isMultiRaces ? '92px' : '65px'}`,
		},
		table: { height: `calc(100vh - ${props.isMultiRaces ? '204px' : '167px'})` },
		footer: {
			height: '40px',
		},
		content: {
			height: 'auto',
		},
		total: {
			//color: theme.palette.primary.main
			//textAlign: 'center'
			//paddingRight: 'calc(100%-20px)'
		},
		numberSpan: {
			display: 'inline-block',
			textAlign: 'right',
			width: '40px',
		},
		typography: {
			flexGrow: 1,
			paddingTop: 2,
			//align: 'center'
		},
	}))

const RANKING_OV_QUERY = gql`
	query rankingOVQuery($rankingID: ID) {
		rankingOV(rankingID: $rankingID) {
			position
			team {
				raceNumber
				teamName
				isRookie
			}
			car {
				model
				classDisplay
				groupDisplay
				groupPrologueDisplay
				coefficientDisplay
			}
			avgText
			classPos
			groupPos
			resultsRT
			resultsCHS
			totalCH
			totalCHS
			totalSC
			totalCP
			totalPen
			totalPointsDay
			pointsBefore
			pointsJokers
			pointsJokersSpecials
			pointsJokersCHS
			totalPointsOV
			difPrevious
			difFirst
			isRetired
		}
	}
`

function RankingOV() {
	const { config } = useConfig()
	const {
		//rallyCode,
		rallyType,
		isPrologue,
		isPrologueDay1,
		days,
		nameDay,
		nameCar,
		isDisplayAVG,
		isDisplayGroup,
		isDisplayGroupPos,
		isDisplayClass,
		isDisplayClassPos,
		isDisplayCoef,
		isCH,
		isCHS,
		isSC,
		isCP,
		isTimeTotal,
		isDecimal,
		jokersSpecialsName,
		isMultiRaces,
		isSplitCHS,
		isJokersCHS,
		isLegsOV,
	} = config
	const { races } = useRaces()
	const classes = useStyles({ isMultiRaces })()
	const intl = useIntl()
	const [selectedRace, setSelectedRace] = useStateWithLocalStorage('selectedRace')
	const [selectedOV, setSelectedOV] = useStateWithLocalStorage(`selectedOV${selectedRace}`)
	const [race, setRace] = useState(null)
	const [stagesRT, setStagesRT] = useState([])
	const [stagesCHS, setStagesCHS] = useState([])
	const [rankingOV, setRankingOV] = useState([])
	const [rankingDay, setRankingDay] = useState(null)
	const [rankingAMPM, setRankingAMPM] = useState(null)
	const [rankingJokers, setRankingJokers] = useState(null)
	const [rankingJokersSpecials, setRankingJokersSpecials] = useState(null)
	const [viewTypeOV, setViewTypeOV] = useStateWithLocalStorage('viewTypeOV', 'summary')

	let msgDay
	switch (nameDay) {
		case 'Day':
			msgDay = 'day'
			break
		case 'Leg':
			msgDay = 'leg'
			break
		case 'Stage':
			msgDay = 'stage'
			break
		default:
			msgDay = 'day'
			break
	}

	const {
		loading: rankingOVLoading,
		error: rankingOVError,
		data: rankingOVData,
	} = useQuery(RANKING_OV_QUERY, {
		skip: !selectedOV,
		pollInterval: config.pollInterval || 60000,
		variables: { rankingID: selectedOV },
	})

	useEffect(() => {
		if (!rankingOVLoading && rankingOVData) {
			setRankingOV(rankingOVData.rankingOV)
		}
	}, [rankingOVLoading, rankingOVData])

	useEffect(() => {
		if (selectedRace) {
			const newRace = races.find(race => race.id.toString() === selectedRace.toString())
			setRace(newRace)
		}
	}, [selectedRace, races])

	if (rankingOVLoading) return 'Loading...'
	if (rankingOVError) return `Error! ${rankingOVError && rankingOVError.message}`

	const columnsRankingOV = [
		{
			Header: <FormattedMessage id="position" />,
			accessor: 'position',
			width: 30,
			sticky: 'left',
			Cell: ({ value }) => (
				<div style={{ textAlign: 'right' }}>
					<strong>{value}</strong>
				</div>
			),
		},
		{
			Header: () => <div style={{ textAlign: 'right' }}>{'#'}</div>,
			accessor: 'team.raceNumber',
			id: 'raceNumber',
			width: 40,
			sticky: 'left',
			Cell: ({ value }) => <div style={{ textAlign: 'right' }}>{value}</div>,
		},
		{
			Header: <FormattedMessage id="crew" />,
			accessor: 'team.teamName',
			Cell: ({ cell }) => {
				return <div dangerouslySetInnerHTML={{ __html: cell.value }} />
			},
			minWidth: 175,
			maxWidth: 200,
		},
	]

	if (viewTypeOV === 'detailed') {
		if (config.isDisplayCarOV) {
			columnsRankingOV.push({
				Header:
					nameCar === 'Vehicle' ? <FormattedMessage id="vehicle" /> : <FormattedMessage id="car" />,
				accessor: 'car.model',
				id: 'car',
				minWidth: 200,
				maxWidth: 220,
			})
		}

		if (isDisplayAVG && race?.isAvg) {
			columnsRankingOV.push({
				Header: <FormattedMessage id="avg" />,
				accessor: 'avgText',
				maxWidth: 60,
			})
		}
		if (isDisplayGroup && (!isMultiRaces || race?.isGroup) && config.rallyType !== 'TAO')
			columnsRankingOV.push({
				Header: (
					<Tooltip title={intl.formatMessage({ id: 'TTgroup' })}>
						<Typography variant="body2" className={classes.typography}>
							{intl.formatMessage({ id: 'groupShort' })}
						</Typography>
					</Tooltip>
				),
				accessor:
					rallyType === 'DKC' && rankingDay === 0 ? 'car.groupPrologueDisplay' : 'car.groupDisplay',
				maxWidth: 50,
			})
		if (isDisplayGroupPos && (!isMultiRaces || race?.isGroup) && config.rallyType !== 'TAO')
			columnsRankingOV.push({
				Header: (
					<Tooltip title={intl.formatMessage({ id: 'TTgroupPos' })}>
						<Typography variant="body2" className={classes.typography}>
							{intl.formatMessage({ id: 'position' })}
						</Typography>
					</Tooltip>
				),
				accessor: 'groupPos',
				maxWidth: 50,
			})
		if (isDisplayClass && (!isMultiRaces || race?.isClass) && config.rallyType !== 'TAO')
			columnsRankingOV.push({
				Header: (
					<Tooltip title={intl.formatMessage({ id: 'TTclass' })}>
						<Typography variant="body2" className={classes.typography}>
							{intl.formatMessage({ id: 'classShort' })}
						</Typography>
					</Tooltip>
				),
				accessor: 'car.classDisplay',
				maxWidth: 50,
			})
		if (isDisplayClassPos && (!isMultiRaces || race?.isClass) && config.rallyType !== 'TAO')
			columnsRankingOV.push({
				Header: (
					<Tooltip title={intl.formatMessage({ id: 'TTclassPos' })}>
						<Typography variant="body2" className={classes.typography}>
							{intl.formatMessage({ id: 'position' })}
						</Typography>
					</Tooltip>
				),
				accessor: 'classPos',
				maxWidth: 50,
			})
		if (config.rallyType === 'TAO' && race?.isGroup && race?.isClass) {
			columnsRankingOV.push(
				{
					Header: 'G-C',
					maxWidth: 80,
					accessor: row => `${row.groupDisplay}${row.classDisplay}`,
				},
				{
					Header: 'Pos',
					maxWidth: 80,
					accessor: row => `${row.groupPos} - ${row.classPos}`,
				}
			)
		}
		if (isDisplayCoef && (!isMultiRaces || race?.isCoef)) {
			columnsRankingOV.push({
				Header: <FormattedMessage id="coef" />,
				accessor: 'car.coefficientDisplay',
				maxWidth: 60,
				Cell: ({ cell }) => <i>{cell.value}</i>,
			})
		}
		if (rankingDay > 1) {
			let previousHeader = ''
			if (isLegsOV) {
				if (rankingDay === 2) {
					previousHeader =
						rankingAMPM === 'AM'
							? `${intl.formatMessage({ id: msgDay })} 1`
							: `${intl.formatMessage({ id: msgDay })} 1-2 AM`
				} else {
					previousHeader =
						rankingAMPM === 'AM'
							? `${intl.formatMessage({ id: msgDay })} 1-${rankingDay - 1}`
							: `${intl.formatMessage({ id: msgDay })} 1-${rankingDay} AM`
				}
			} else {
				if (rankingDay === 2) {
					previousHeader = `${intl.formatMessage({ id: msgDay })} 1`
				} else {
					previousHeader = `${intl.formatMessage({ id: msgDay })} 1-${rankingDay - 1}`
				}
			}
			columnsRankingOV.push({
				Header: `${previousHeader}`,
				id: 'previousDays',
				maxWidth: 100,
				accessor: d => {
					const value = isTimeTotal === true ? pointsToTime(d.pointsBefore) : d.pointsBefore
					return value
				},
				Cell: ({ cell }) => <span className={classes.numberSpan}>{cell.value}</span>,
			})
		}
		if (stagesRT.length) {
			for (const [i, value] of stagesRT.entries()) {
				columnsRankingOV.push({
					Header: <span className={classes.numberSpan}>{`${value.nameText}`}</span>,
					accessor: 'resultsRT[' + i + ']',
					minWidth: 70,
					maxWidth: 100,
					id: value.id,
					Cell: ({ cell }) => <span className={classes.numberSpan}>{cell.value}</span>,
				})
			}
		}
		if (stagesCHS.length && isSplitCHS) {
			for (const [i, value] of stagesCHS.entries()) {
				columnsRankingOV.push({
					Header: (
						<span>{`${intl.formatMessage({
							id: 'chs',
						})}${value.name.substring(3)}`}</span>
					),
					accessor: 'resultsCHS[' + i + ']',
					minWidth: 70,
					maxWidth: 100,
					id: value.id,
					Cell: ({ cell }) => <span className={classes.numberSpan}>{cell.value}</span>,
				})
			}
		}
		if (isCH)
			columnsRankingOV.push({
				Header: (
					<span className={classes.numberSpan}>
						<Tooltip title={intl.formatMessage({ id: 'TTch' })}>
							<Typography variant="body2" className={classes.typography}>
								{intl.formatMessage({ id: 'ch' })}
							</Typography>
						</Tooltip>
					</span>
				),
				accessor: 'totalCH',
				maxWidth: 60,
				Cell: ({ cell }) => <span className={classes.numberSpan}>{cell.value}</span>,
			})
		if (isCHS)
			columnsRankingOV.push({
				Header: 'TCS',
				accessor: 'totalCHS',
				maxWidth: 60,
				Cell: ({ cell }) => <span className={classes.numberSpan}>{cell.value}</span>,
			})
		if (isSC)
			columnsRankingOV.push({
				Header: (
					<span className={classes.numberSpan}>
						<Tooltip title={intl.formatMessage({ id: 'TTsc' })}>
							<Typography variant="body2" className={classes.typography}>
								{intl.formatMessage({ id: 'sc' })}
							</Typography>
						</Tooltip>
					</span>
				),
				accessor: 'totalSC',
				maxWidth: 60,
				Cell: ({ cell }) => <span className={classes.numberSpan}>{cell.value}</span>,
			})
		if (isCP)
			columnsRankingOV.push({
				Header: (
					<span className={classes.numberSpan}>
						<Tooltip title={intl.formatMessage({ id: 'TTcp' })}>
							<Typography variant="body2" className={classes.typography}>
								{intl.formatMessage({ id: 'cp' })}
							</Typography>
						</Tooltip>
					</span>
				),
				accessor: 'totalCP',
				maxWidth: 60,
				Cell: ({ cell }) => <span className={classes.numberSpan}>{cell.value}</span>,
			})
		columnsRankingOV.push({
			Header: (
				<span className={classes.numberSpan}>
					<Tooltip title={intl.formatMessage({ id: 'TTpen' })}>
						<Typography variant="body2" className={classes.typography}>
							{intl.formatMessage({ id: 'pen' })}
						</Typography>
					</Tooltip>
				</span>
			),
			accessor: 'totalPen',
			maxWidth: 60,
			Cell: ({ cell }) => <span className={classes.numberSpan}>{cell.value}</span>,
		})
		if (rankingDay > 1)
			columnsRankingOV.push({
				Header: `${intl.formatMessage({ id: msgDay })} ${rankingDay} ${
					isLegsOV && rankingAMPM === 'PM' ? 'PM' : isLegsOV && rankingAMPM === 'AM' ? 'AM' : ''
				}`,
				accessor: 'totalPointsDay',
				minWidth: 70,
				maxWidth: 100,
				Cell: ({ cell }) => <span className={classes.numberSpan}>{cell.value}</span>,
			})
		if (rankingJokers)
			columnsRankingOV.push({
				Header: <FormattedMessage id="joker" />,
				accessor: 'pointsJokers',
				maxWidth: 60,
				Cell: ({ cell }) => <span className={classes.numberSpan}>{cell.value}</span>,
			})
		if (rankingJokersSpecials)
			columnsRankingOV.push({
				Header: jokersSpecialsName,
				accessor: 'pointsJokersSpecials',
				maxWidth: 60,
				Cell: ({ cell }) => <span className={classes.numberSpan}>{cell.value}</span>,
			})
		if (isJokersCHS)
			columnsRankingOV.push({
				Header: 'Jok. TCS',
				accessor: 'pointsJokersCHS',
				maxWidth: 60,
			})
	}

	columnsRankingOV.push({
		Header: (
			<span className={classes.numberSpan}>
				<FormattedMessage id="total" />
			</span>
		),
		accessor: d => {
			const value =
				isTimeTotal === true
					? pointsToTime(d.totalPointsOV, isDecimal)
					: race?.isCoef || isDecimal
					? Number(d.totalPointsOV).toFixed(2)
					: d.totalPointsOV
			return value
		},
		id: 'total',
		//maxWidth: 50,
		Cell: ({ cell }) => (
			<span className={classes.numberSpan}>
				<strong>{cell.value}</strong>
			</span>
		),
	})
	if (viewTypeOV === 'detailed') {
		columnsRankingOV.push({
			Header: <FormattedMessage id="difference" />,
			id: 'difPrevious',
			accessor: row =>
				isTimeTotal && row.difPrevious !== '='
					? pointsToTime(row.difPrevious, isDecimal, 'dif')
					: row.difPrevious,
			maxWidth: isTimeTotal ? 120 : 80,
		})
		columnsRankingOV.push({
			Header: <FormattedMessage id="differenceFirst" />,
			id: 'difFirst',
			accessor: row =>
				isTimeTotal && row.difFirst !== '='
					? pointsToTime(row.difFirst, isDecimal, 'dif')
					: row.difFirst,
			maxWidth: isTimeTotal ? 120 : 80,
		})
		/*
		if (rallyType === 'DKC') {
			const rookieLogoPath = `races/${rallyCode}rookie.png`
			columnsRankingOV.splice(2, 0, {
				Header: <FormattedMessage id="experience" />,
				accessor: 'team.isRookie',
				Cell: ({ cell }) =>
					cell.value === true ? <Image publicId={rookieLogoPath} width="20" height="25" /> : null,
				maxWidth: 25,
			})
		}*/
	}
	const lastDay = isPrologue && !isPrologueDay1 ? days - 1 : days

	return (
		<>
			<div className={classes.menu}>
				<MenuOV
					rankingSelectedOV={selectedOV}
					setRankingSelectedOV={setSelectedOV}
					rankingSelectedRace={selectedRace}
					setRankingSelectedRace={setSelectedRace}
					setStagesRT={setStagesRT}
					setStagesCHS={setStagesCHS}
					setRankingDay={setRankingDay}
					setRankingAMPM={setRankingAMPM}
					setRankingJokers={setRankingJokers}
					setRankingJokersSpecials={setRankingJokersSpecials}
					rankingDay={rankingDay}
					lastDay={lastDay}
					msgDay={msgDay}
				/>
			</div>
			{rankingOV && rankingOV.length > 0 && (
				<>
					<div className={classes.table}>
						<MUITable
							columns={columnsRankingOV}
							data={rankingOV}
							type="rankingOV"
							isMultiRaces={isMultiRaces}
						/>
					</div>
					<div className={classes.footer}>
						<FooterOV
							viewTypeOV={viewTypeOV}
							setViewTypeOV={setViewTypeOV}
							className={classes.footer}
							//style={{ position: 'sticky' }}
						/>
					</div>
				</>
			)}
		</>
	)
}

export default RankingOV
